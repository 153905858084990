import React from 'react';
import './App.scss';
import bg from './assets/bg.mp4';
import wc from './assets/wc.png';
import maverik from './assets/maverik.png';

function App() {
  return (
    <div className="App">
      <video autoPlay loop muted src={bg} />
      <a href="https://x.com/WCstud_io/" target="_blank">
        <img src={wc} alt="" className="top-right" />
      </a>
      <a href="https://linktr.ee/MaverikAgency/" target="_blank">
        <img src={maverik} alt="" className="top-left" />
      </a>
    </div>
  );
}

export default App;
